export enum Suit {
    LEAF="LEAF",
    BRAIN="BRAIN",
    COG="COG",
    MATH="MATH",
}

export const ALL_SUITS = [
    Suit.BRAIN,
    Suit.COG,
    Suit.LEAF,
    Suit.MATH
];
